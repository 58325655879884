<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/content.svg" alt />
        <span class="center">内容管理</span>
      </div>
      <div style="display: flex; align-items: center">
        <span class="seach_text">关键字：</span>

        <el-input
          placeholder="请输入关键字"
          v-model="formInline.keywords"
          @input="loadData('seath')"
          size="mini"
          style="margin-right: 10px; width: 300px"
          prefix-icon="el-icon-search"
          clearable
        ></el-input>
        <el-button @click="addObject" size="small" type="primary" icon="el-icon-plus">新建</el-button>
      </div>
    </div>

    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="all"></el-tab-pane>
      <el-tab-pane :label="item" :name="item" v-for="(item, key) in typeList" :key="key"></el-tab-pane>
    </el-tabs>

    <!-- <el-form
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
      style="text-align: left"
    >
      <el-form-item label="关键字">
        <el-input
          v-model="formInline.keywords"
          placeholder="请输入内容名称"
          @input="loadData()"
          size="small"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="addObject" size="small" plain icon="el-icon-plus"
          >新建</el-button
        >
      </el-form-item>
    </el-form>-->
    <el-table :data="list" style="width: 100%">
      <el-table-column label="序号" width="60" type="index"></el-table-column>
      <!-- <el-table-column label="序号" width="60">
            <template slot-scope="scope">{{total-pageNumber*pageSize+scope.$index}}</template>
      </el-table-column>-->
      <el-table-column label="名称">
        <template slot-scope="scope">
          <span class="clickColor" @click="goDetail({ code: scope.row.code })">{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="分类">
        <template slot-scope="scope">{{ scope.row.type }}</template>
      </el-table-column>
      <el-table-column label="备注">
        <template slot-scope="scope">{{ scope.row.remark }}</template>
      </el-table-column>
      <el-table-column label="内容更新时间">
        <template slot-scope="scope">{{ scope.row.updateTime }}</template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <!-- <el-popconfirm
              v-if="scope.row.state !== '5'"
              @confirm="del({ code: scope.row.code })"
              confirmButtonText="确定"
              cancelButtonText="取消"
              icon="el-icon-info"
              iconColor="red"
              title="确定删除此这条数据？"
            >
              <span class="clickColor" slot="reference">删除</span>
          </el-popconfirm>-->
          <div class="shuxian" v-if="scope.row.state !== '5'">
            <el-popover
              placement="top"
              width="400"
              trigger="manual"
              :value="visible"
              :ref="'popover-' + scope.row.id"
            >
              <div class="del-pop">
                <img src="../../assets/del.png" alt />
              </div>
              <div class="del-title">删除内容</div>
              <div class="del-sumtitle">是否删除本条内容？</div>
              <div class="del-btn">
                <el-button type="primary" @click="del(scope.row)" plain>确认</el-button>
                <el-button type="info" @click="doCancel(scope.row.id)" plain>取消</el-button>
              </div>
              <el-button type="text" slot="reference" @click="pOpen(scope.row.id)">删除</el-button>
            </el-popover>
            <el-divider direction="vertical"></el-divider>
          </div>

          <span class="clickColor" @click="goEdit(scope.row.code)">编辑</span>
          |
          <span class="clickColor" @click="goContent(scope.row.code)">预览内容</span>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <span class="clickColor">共{{ total }}条</span>
      <span class="clickColor" style="margin:0 10px">每页{{ pageSize }}条</span>
      <el-pagination
        background
        :current-page.sync="pageNumber"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="prev,pager, next,jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
// import config from "../../config/config";
// 接口：
import { api } from '../../api/base/index';
// 新增接口
export const ContentAdd = api('/content')('content.add.json');
// 查询列表接口
export const ContentList = api('/content')('content.list.json');
// 查询详情接口
export const ContentDetail = api('/content')('content.describe.json');
// 编辑接口
export const ContentEdit = api('/content')('content.edit.json');
// 删除接口
export const ContentDel = api('/content')('content.del.json');
export const ContentTypeList = api('/content')('content.type.list.json');
export default {
  components: {},
  data () {
    return {
      focusCode: '',
      pageNumber: 1,
      visible: false,
      pageSize: 10,
      total: 0,
      formInline: {
        keywords: ''
      },
      list: [],
      activeName: 'all',
      typeList: []
    };
  },
  created () {
    this.getTypeList();
    this.loadData();
  },
  methods: {
    pOpen (id) {
      this.$refs['popover-' + id].doShow();
    },
    doCancel (id) {
      this.pClose(id);
    },
    pClose (id) {
      this.$refs['popover-' + id].doClose();
      console.log(this.$refs['popover-' + id]);
    },
    async getTypeList () {
      this.typeList = await ContentTypeList();
    },
    handleSizeChange (val) {
      this.pageSize = val;
      this.loadData();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.loadData();
    },
    loadData (seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const params = {
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        ...this.formInline,
        type: this.activeName === 'all' ? '' : this.activeName,
        $ignoreRepeat: true
      };
      ContentList(params).then(res => {
        this.list = res.list;
        this.total = res.total;
      });
    },
    onSubmit () {
      console.log('submit!');
    },
    addObject () {
      this.$router.push({
        path: '/system/content/addContent'
      });
    },
    goEdit (code) {
      this.$router.push({
        path: '/system/content/editContent',
        query: { code }
      });
    },
    goDetail (obj) {
      this.$router.push({
        path: '/system/content/Detail',
        query: obj
      });
    },
    del (obj) {
      ContentDel({ code: obj.code }).then(() => {
        this.$message.success('内容成功删除！');
        this.doCancel(obj.id);
        this.loadData();
      });
    },
    goContent (code) {
      window.open(
        `${window.location.origin}/content/${code}/document.html?skin=document`
      );
    },
    handleClick (tab) {
      this.activeName = tab.name;
      this.loadData();
    },
    putAway () {
      this.formInline.keywords = '';
      this.pageNumber = 1;
      this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped>
.clickColor {
  color: #409eff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.demo-form-inline {
  display: flex;
  flex-wrap: wrap;
}
</style>
